export default {
  name: 'ConfirmationModal',
  props: { promptMessage: String },
  created() {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  },
  destroyed() {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('close');
    },
    onEscapePressed() {
      this.cancel();
    }
  }
};
