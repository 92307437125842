import httpService from './httpService';

const getBySupplierId = async id => {
  const { data } = await httpService.get(`/contacts/supplier/${id}`);
  return data;
};

const getByUser = async username => {
  const { data } = await httpService.get(`/contacts/user/${username}`);
  return data;
};

const createContact = async user => {
  const { data } = await httpService.post('/contacts', user);
  return data;
};

const deleteContact = async contactId => {
  const { data } = await httpService.delete(`/contacts/${contactId}`);
  return data;
};

const isPurchasingManager = async (supplierId, userid) => {
  const contacts = await getBySupplierId(supplierId);

  const filteredContacts = contacts.filter(contact => contact.user.username === userid);

  if (filteredContacts.filter(contact => contact.role.name === 'Purchasing Manager').length >= 1) {
    return true;
  }
  return false;
};

const ContactService = {
  getBySupplierId,
  getByUser,
  createContact,
  deleteContact,
  isPurchasingManager
};

export default ContactService;
