import { mapGetters } from 'vuex';
import ModalFormRating from '../ModalFormRating/ModalFormRating.vue';
import ConfirmationModal from '../../../ConfirmationModal/ConfirmationModal.vue';
import ratingService from '../../../../../services/RatingService';

export default {
  name: 'RatingContainer',
  props: { Rating: Object, categories: Array },
  components: { 'edit-rating-modal': ModalFormRating, ConfirmationModal },
  data() {
    return {
      expand: false,
      showEditModal: false,
      showDeleteModal: false,
      filteredNewCategories: [],
      filteredOldCategories: [],
      filteredOldRatings: [],
      filteredNewRatings: []
    };
  },
  methods: {
    toggleExpand() {
      this.expand = !this.expand;
    },
    async editRating(ratingData) {
      await ratingService.updateRating(ratingData.id, ratingData);
      this.$emit('edit');
      this.toggleShowEditModal();
    },
    async deleteRating() {
      await ratingService.deleteById(this.Rating.id);
      this.$emit('delete', this.Rating.id);
      this.toggleShowDeleteModal();
    },
    toggleShowEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleShowDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    }
  },
  created() {
    this.filteredOldCategories = this.categories.filter(c => c === 'Relationship' || c === 'Strategy' || c === 'Financials');
    this.filteredNewCategories = this.categories.filter(c => c === 'Simplified');

    this.filteredOldRatings = this.Rating.ratingScores.filter(r => this.filteredOldCategories.indexOf(r.category) >= 0);
    this.filteredNewRatings = this.Rating.ratingScores.filter(r => this.filteredNewCategories.indexOf(r.category) >= 0);
  },
  computed: {
    ...mapGetters(['getClickedSupplier', 'getCurrentUser']),
    userIsAllowed() {
      return !this.Rating.isConfidential || this.Rating.userIsAllowed;
    },
    averageRating() {
      // Calculate average score from all non-zero values
      const value = this.Rating.ratingScores.reduce((acc, score) => acc + score.value, 0) / this.Rating.ratingScores.filter(score => score.value !== 0).length;
      return Math.round(value);
    },
    scoresByCategory() {
      // Pepare an object with all categories --> {Relationship: [score-from-relationship-1,score-from-relationship-2], ...}
      const categoryMap = this.categories.reduce((acc, category) => ({ ...acc, [category]: this.Rating.ratingScores.filter(score => score.category === category) }), {});

      categoryMap.Simplified = {
        Quality: categoryMap.Simplified.find(s => s.name === 'Quality'),
        Costs: categoryMap.Simplified.find(s => s.name === 'Costs'),
        Innovation: categoryMap.Simplified.find(s => s.name === 'Innovation'),
        Relationship: categoryMap.Simplified.find(s => s.name === 'Relationship')
      };

      return categoryMap;
    }
  }
};
