import httpService from './httpService';

const getRisksBySupplier = async (supplierId) => {
  const { data } = await httpService.get(`/risks/supplier/${supplierId}`);
  return data;
};

export default {
  getRisksBySupplier,
};
