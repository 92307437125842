var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"segment-header"},[_c('a',{staticClass:"return-link",on:{"click":_vm.backAction}},[_c('i',{staticClass:"icon icon-014-chevron-left icon--s1",attrs:{"aria-hidden":"true"}}),_vm._v(" Back to "+_vm._s(_vm.backText)+" ")]),_c('h2',{staticClass:"landscape-title"},[_c('span',{staticClass:"landscape-segment-title"},[_vm._v("Partner Landscape of")]),_c('span',{staticClass:"landscape-segment-name"},[_vm._v(_vm._s(_vm.segment.name))])])]),(!_vm.isLoading)?_c('div',{staticClass:"landscape"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"cell"}),_vm._l((_vm.valueChainStages),function(valueChainStage){return _c('div',{key:valueChainStage.id,staticClass:"cell header-item"},[_c('div',{staticClass:"inner-cell"},[_c('i',{staticClass:"icon icon--s2",class:valueChainStage.icon}),_c('span',{staticClass:"text"},[_vm._v(_vm._s(valueChainStage.name))])])])})],2),_vm._l((_vm.partnershipStates),function(partnershipStatus){return _c('div',{key:partnershipStatus.id,staticClass:"item-row"},[_c('div',{staticClass:"title-cell"},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(partnershipStatus.name))]),_c('v-popover',{attrs:{"placement":"right","offset":"15"}},[_c('i',{staticClass:"icon icon-046-information-circle clickable"}),_c('template',{slot:"popover"},[_c('h3',{staticClass:"popover-title"},[_vm._v(_vm._s(partnershipStatus.name))]),_c('div',{domProps:{"innerHTML":_vm._s(partnershipStatus.description)}})])],2)],1),_vm._l((_vm.valueChainStages),function(valueChainStage){return _c('div',{key:valueChainStage.id,staticClass:"cell"},[_c('div',{staticClass:"inner-cell"},[_vm._l((_vm.getSuppliersForCell(
              valueChainStage.id,
              partnershipStatus.id
            )[1]),function(supplier){return _c('router-link',{key:supplier.id,staticClass:"supplier",attrs:{"to":`/supplier/${supplier.supplier.id}`,"tag":"div"}},[_c('span',{staticClass:"text"},[_vm._v(_vm._s(supplier.supplier.name))])])}),(
              _vm.getSuppliersForCell(
                valueChainStage.id,
                partnershipStatus.id,
                true
              )[0] > 3 &&
                !_vm.isCellExpanded(valueChainStage.id, partnershipStatus.id)
            )?_c('a',{on:{"click":function($event){return _vm.addExpandedCell(valueChainStage.id, partnershipStatus.id)}}},[_vm._v("show more")]):_vm._e()],2)])})],2)})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }