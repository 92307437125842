/* eslint-disable */

import Vue from 'vue';
import Router from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';
import SupplierDetail from './views/SupplierDetail/SupplierDetail.vue';
import SupplierOverview from './views/SupplierOverview/SupplierOverview.vue';
import AccessForbidden from './views/AccessForbidden/AccessForbidden.vue';
import History from './views/History/History.vue';
import store from './store';
import OidcCallback from './components/oidcCallback/oidcCallback.vue';
import SegmentLandscape from './views/SegmentLandscape/SegmentLandscape.vue';
import Upload from './views/Upload/Upload.vue';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'home',
    component: SupplierOverview
  },
  {
    path: '/access-forbidden',
    name: 'AccessForbidden',
    component: AccessForbidden
  },
  {
    path: '/supplier/:id',
    name: 'detail',
    component: SupplierDetail
  },
  {
    path: '/segments/:id',
    name: 'segment-landscape',
    component: SegmentLandscape
  },
  {
    path: '/supplier/:id/information-history',
    name: 'history',
    component: History
  },
  {
    path: '/oidc/callback',
    name: 'oidcCallback',
    component: OidcCallback,
    meta: {
      isOidcCallback: true
    }
  },
  {
    path: '/upload',
    name: 'upload',
    component: Upload
  }
];

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

const routerMiddleware = async (to, from, next) => {
  if (store.state.oidcStore.is_checked) {
    if (!store.state.User.currentUser.username) {
      await store.dispatch('loadCurrentUser');
    }
    // eslint-disable-next-line no-extra-boolean-cast

    if (!!store.state.User.currentUser.username) {
      const allowedExtUsers = process.env.VUE_APP_ALLOWED_EXTERNAL.split(';');
      if (Number(store.state.User.currentUser.isExternal) && allowedExtUsers.filter(user => user.toLowerCase() === store.state.User.currentUser.username.toLowerCase()).length === 0) {
        if (to.path !== '/access-forbidden') {
          next('/access-forbidden');
        }
      }
      next();
    }
    next();
  }
  next();
};

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));
router.beforeEach(routerMiddleware);

export default router;
