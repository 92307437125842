export default {
  name: 'Upload',
  data: {},
  methods: {
    goBack() {
      if (this.lastRoute) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    }
  }
};
