import { render, staticRenderFns } from "./RatingContainer.vue?vue&type=template&id=5fbbe41f&scoped=true"
import script from "./RatingContainer.js?vue&type=script&lang=js&external"
export * from "./RatingContainer.js?vue&type=script&lang=js&external"
import style0 from "./RatingContainer.scss?vue&type=style&index=0&id=5fbbe41f&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fbbe41f",
  null
  
)

export default component.exports