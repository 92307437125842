/* eslint-disable max-len */
import { VueEditor } from 'vue2-editor';
import { mapGetters } from 'vuex';
import risksService from '../../../../services/RisksService';
import InformationService from '../../../../services/InformationService';

export default {
  name: 'Info',
  components: {
    VueEditor
  },
  data() {
    return {
      history: undefined,
      initialState: {
        cdp: false,
        createdAt: '',
        ecovadisEnvironment: '',
        ecovadisEthics: '',
        ecovadisLink: '',
        ecovadisProcurement: '',
        ecovadisTotalScore: '',
        ecovadisWork: '',
        id: '',
        srn: false,
        srnLink: '',
        updatedAt: ''
      },
      showPopUp: false,
      editMode: false,
      note: '',
      risks: {},
      showTable: false,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [
          {
            list: 'ordered'
          },
          {
            list: 'bullet'
          }
        ]
      ]
    };
  },
  async created() {
    this.risks = await risksService.getRisksBySupplier(this.getClickedSupplier.id).catch(() => this.initialState);
    if (!this.risks || this.risks.ecovadisTotalScore === '' || this.risks.ecovadisTotalScore === null) {
      this.showTable = false;
    } else {
      this.showTable = true;
    }

    if (!this.getClickedSupplier.information) {
      this.getClickedSupplier.information = await InformationService.getInformationBySupplier(this.getClickedSupplier.id);
      if (!this.getClickedSupplier.information && !this.getClickedSupplier.information.text) {
        this.getClickedSupplier.information = {};
      }
    }
    this.history = await InformationService.getInformationHistory(
      this.getClickedSupplier.id
      // eslint-disable-next-line no-return-assign
    ).then(response => (this.getClickedSupplier.informationHistory = response));
  },
  methods: {
    getColorName(score) {
      // eslint-disable-next-line no-nested-ternary
      return score < 25 ? '#d12' : score > 25 && score < 45 ? '#ff8b2e' : score > 44 && score < 65 ? '#25b252' : '#008236';
    },
    goTo(location) {
      this.$router.push(location);
    },
    hasLink(link) {
      return link;
    },
    toggleEditMode() {
      this.editMode = !this.editMode;
    },
    async saveInformation() {
      this.getClickedSupplier.information = await InformationService.saveInformation(this.getClickedSupplier.information, this.getClickedSupplier.id);
      this.editMode = false;
    }
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),
    getInformation() {
      return this.getClickedSupplier.information;
    },
    hasInformation() {
      return !!this.getClickedSupplier.information && !!this.getClickedSupplier.information.text ? this.getClickedSupplier.information.text.trim().length > 0 : false;
    },
    hasHistory() {
      return !!this.history && this.history.length > 0;
    }
  }
};
