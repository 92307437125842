import httpService from './httpService';

const getSupplierContracts = async (id) => {
  const { data } = await httpService.get(`/contracts/supplier/${id}`);
  return data;
};

const createContract = async (contract, supplierId) => {
  const { data } = await httpService.post(`/contracts/supplier/${supplierId}`, contract);
  return data;
};

const deleteContract = async (contractId) => {
  const { data } = await httpService.delete(`/contracts/${contractId}`);
  return data;
};

const getContractTypes = async () => {
  const { data } = await httpService.get('/contracts/types');
  return data;
};

const ContractService = {
  getSupplierContracts,
  createContract,
  deleteContract,
  getContractTypes,
};

export default ContractService;
