import { mapGetters, mapMutations } from 'vuex';
import addYears from 'date-fns/addYears';
import startOfYear from 'date-fns/startOfYear';
import formatISO from 'date-fns/formatISO';
import Typeahead from '../../../../../components/Typeahead/Typeahead.vue';

import ValueChainStepExplanation from '../ValueChainStepExplanation.vue';

import SegmentService from '../../../../../services/SegmentService';

export default {
  name: 'Segment-Form',
  props: {
    segment: {
      type: Object,
      required: false
    }
  },
  data: () => ({
    placeholder: 'e.g. "INI-DOS", "Internet Of Things" or "WGr Software Standard"',
    isNewSegment: false,
    formData: {
      name: '',
      partnershipStatus: null,
      valueChainStages: [],
      exitAndLegacyDate: null,
      exitDateSpecified: false
    },
    availableValueChainStages: [],
    availablePartnershipStates: [],
    availableDates: []
  }),
  components: {
    Typeahead,
    ValueChainStepExplanation
  },
  async created() {
    this.setSegmentConflictError(false);
    [this.availableValueChainStages, this.availablePartnershipStates] = await Promise.all([SegmentService.getAvailableValueChainStages(), SegmentService.getAvailablePartnershipStates()]);

    this.availableDates = this.getNextYears(5);
    if (this.segment) {
      this.formData = this.segment;
    }
  },
  methods: {
    ...mapMutations(['setSegmentConflictError']),
    searchSegments: SegmentService.search,
    toggleModal() {
      this.$emit('toggleModal');
    },
    toggleValueChainStage(valueChainStage) {
      const existingItem = this.formData.valueChainStages.find(item => item.id === valueChainStage.id);
      if (existingItem) {
        // Remove
        this.formData.valueChainStages = this.formData.valueChainStages.filter(item => item.id !== existingItem.id);
      } else {
        // Add
        this.formData.valueChainStages.push(valueChainStage);
      }
    },
    saveSegment() {
      const payload = {
        segmentName: this.formData.name,
        partnershipStatusId: this.formData.partnershipStatus.id,
        valueChainStageIds: this.formData.valueChainStages.map(v => v.id),
        exitAndLegacyDate: this.formData.partnershipStatus.name === 'Exit & Legacy' ? this.formData.exitAndLegacyDate : null,
        exitDateSpecified: this.formData.exitDateSpecified
      };
      this.$emit('segmentSaved', { payload });
    },
    existingSegmentSelected(segment) {
      this.formData.name = segment.name;
    },
    setSelectedState(date) {
      this.formData.exitAndLegacyDate = date;
      this.formData.exitDateSpecified = true;
    },
    setNotSpecified() {
      this.formData.exitDateSpecified = false;
      this.formData.exitAndLegacyDate = null;
    },
    getNextYears(amount) {
      const years = [formatISO(startOfYear(new Date()))];
      for (let i = 0; i < amount; i += 1) {
        years.push(formatISO(addYears(startOfYear(new Date()), i + 1)));
      }
      return years;
    },
    formatDate(date) {
      if (date) {
        return date.slice(0, 4);
      }
      return null;
    }
  },
  computed: {
    ...mapGetters(['hasSegmentConflictError']),
    isFormValid() {
      const hasValueChainStages = Boolean(this.formData.valueChainStages.length);
      const hasPartnershipStatus = Boolean(this.formData.partnershipStatus);
      const hasName = Boolean(this.formData.name.length);
      return hasValueChainStages && hasPartnershipStatus && hasName;
    }
  }
};
