import { mapGetters } from 'vuex';
import SupplierService from '../../../../services/SupplierService';

export default {
  name: 'PopUp',
  props: {
    supplier: Object
  },
  data: () => ({
    conatinerDisplay: 'block',
    popUp: {
      start: '',
      text: '',
      end: ''
    }
  }),
  async created() {
    if (!this.getClickedSupplier.popup) {
      const { data, status } = await SupplierService.getPopUp(this.getClickedSupplier.id);

      if (status === 204) {
        return;
      }
      this.getClickedSupplier.popup = data;
    }
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),

    checkData() {
      if (this.getClickedSupplier.popup.start === '') {
        return true;
      }
      return false;
    }
  }
};
