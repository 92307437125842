var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"segment-form"},[_c('div',{staticClass:"modal-heading"},[_c('h2',[_vm._v(_vm._s(!_vm.segment ? "Add new" : "Change")+" Segment")]),_c('i',{staticClass:"icon icon-022-close icon--s3 clickable",on:{"click":function($event){return _vm.toggleModal()}}})]),_c('div',{staticClass:"form-section"},[_c('p',{staticClass:"form-field-heading"},[_vm._v(" Segment Name (Organization Entity/Product/Category) ")]),_c('Typeahead',{attrs:{"searchFunction":_vm.searchSegments,"error":_vm.hasSegmentConflictError
          ? 'Segment already exists for this supplier. Edit existing segment or adjust entered segment name.'
          : null,"displayProperty":"name","disabled":Boolean(this.segment),"placeholder":_vm.placeholder},on:{"searchResultSelected":_vm.existingSegmentSelected},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})],1),_c('div',{staticClass:"form-section"},[_c('p',{staticClass:"form-field-heading",attrs:{"for":"segment-partnership-state"}},[_vm._v(" Which is the status of the partnership for this segment? ")]),_c('div',{staticClass:"flex-wrapper"},[_c('div',_vm._l((_vm.availablePartnershipStates),function(state,index){return _c('div',{key:'partnership-state-' + index,staticClass:"partnership-state-item"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.partnershipStatus),expression:"formData.partnershipStatus"}],attrs:{"type":"radio","name":"segment-partnership-state","id":'partnership-state-' + index},domProps:{"value":state,"checked":_vm._q(_vm.formData.partnershipStatus,state)},on:{"change":function($event){return _vm.$set(_vm.formData, "partnershipStatus", state)}}}),_c('label',{attrs:{"for":'partnership-state-' + index}},[_vm._v(_vm._s(state.name))])])}),0),(_vm.formData.partnershipStatus)?_c('div',{staticClass:"selected-partnershipstate-display"},[_c('h3',[_vm._v(_vm._s(_vm.formData.partnershipStatus.name))]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.formData.partnershipStatus.description)}})]):_vm._e()])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.formData.partnershipStatus
        ? _vm.formData.partnershipStatus.name === 'Exit & Legacy'
        : false
    ),expression:"\n      formData.partnershipStatus\n        ? formData.partnershipStatus.name === 'Exit & Legacy'\n        : false\n    "}],staticClass:"form-section"},[_c('p',{staticClass:"form-field-heading"},[_vm._v(" Select exit and legacy date "),_c('v-popover',{attrs:{"placement":"top","offset":"15"}},[_c('i',{staticClass:"icon icon-046-information-circle clickable",staticStyle:{"margin-left":"5px"}}),_c('template',{slot:"popover"},[_vm._v("Text to be defined")])],2)],1),_c('div',{staticClass:"value-chain-container"},[_vm._l((_vm.availableDates),function(date,index){return _c('div',{key:'value-chain-stage-' + index,staticClass:"value-chain-stage-item clickable",class:{
          selected:
            _vm.formatDate(_vm.formData.exitAndLegacyDate) === _vm.formatDate(date),
        },on:{"click":function($event){return _vm.setSelectedState(date)}}},[_vm._v(" "+_vm._s(_vm.formatDate(date))+" ")])}),_c('div',{staticClass:"value-chain-stage-item clickable",class:{ selected: _vm.formData.exitDateSpecified === false },on:{"click":function($event){return _vm.setNotSpecified()}}},[_vm._v(" Not specified ")])],2)]),_c('div',{staticClass:"form-section"},[_c('p',{staticClass:"form-field-heading"},[_vm._v(" Select the relevant value chain stage(s) for this segment. (multiple choices possible) "),_c('v-popover',{attrs:{"placement":"top","offset":"15"}},[_c('i',{staticClass:"icon icon-046-information-circle clickable",staticStyle:{"margin-left":"5px"}}),_c('template',{slot:"popover"},[_c('ValueChainStepExplanation',{attrs:{"valueChainStages":_vm.availableValueChainStages}})],1)],2)],1),_c('div',{staticClass:"value-chain-container"},_vm._l((_vm.availableValueChainStages),function(stage,index){return _c('div',{key:'value-chain-stage-' + index,staticClass:"value-chain-stage-item clickable",class:{
          selected: _vm.formData.valueChainStages.some(
            (valueChainStage) => valueChainStage.id === stage.id
          ),
        },on:{"click":function($event){return _vm.toggleValueChainStage(stage)}}},[_c('i',{staticClass:"icon icon--s2",class:stage.icon}),_vm._v(" "+_vm._s(stage.name)+" ")])}),0)]),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"button-secondary",on:{"click":function($event){return _vm.toggleModal()}}},[_vm._v("Cancel")]),_c('button',{staticClass:"button-primary",attrs:{"disabled":!_vm.isFormValid},on:{"click":function($event){return _vm.saveSegment()}}},[_vm._v(" "+_vm._s(!_vm.segment ? "Add Segment" : "Update Segment")+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }