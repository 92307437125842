<template>
  <div>
    <p>
      <b>
        You can determine the supplier/partner type by answering the following
        question:
      </b>
      <br />
      <b>
        Where does the performance of the supplier affect the Swisscom Value
        Chain?
      </b>
    </p>

    <div v-for="(stage, index) in valueChainStages" :key="index" class="stage-item">
      <b>{{ stage.name }}</b>
      <p>{{ stage.description }}</p>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
export default {
  name: 'ValueChainStepExplanation',
  props: ['valueChainStages']
};
</script>
<style scoped lang="scss">
.stage-item {
  display: flex;
  margin-top: 20px;

  b {
    width: 30%;
  }

  p {
    width: 70%;
  }
}
</style>
