import ClickOutside from 'vue-click-outside';

const replaceAll = (str, find, replace) => str.replace(new RegExp(find, 'gi'), replace);

export default {
  name: 'Typeahead',
  props: {
    value: String,
    displayProperty: String,
    searchFunction: Function,
    error: String,
    placeholder: String,
    disabled: Boolean
  },
  data: () => ({
    internalValue: '',
    searchResults: []
  }),
  created() {
    this.internalValue = this.value;
  },
  methods: {
    async inputUpdated() {
      this.searchResults = await this.searchFunction(this.internalValue);
      this.$emit('input', this.internalValue);
    },
    async chooseSearchResult(value) {
      this.internalValue = value[this.displayProperty];
      await this.$emit('searchResultSelected', value);
      this.searchResults = [];
    },
    getMatchHighlight(result) {
      const highlight = result[this.displayProperty].match(new RegExp(this.internalValue, 'i'));
      return replaceAll(result[this.displayProperty], this.internalValue, `<span>${highlight}</span>`);
    },
    hideMenu() {
      this.searchResults = [];
    }
  },
  watch: {
    value(newVal) {
      this.internalValue = newVal;
    }
  },
  directives: {
    ClickOutside
  }
};
