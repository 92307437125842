/* eslint-disable no-console */
import { mapGetters, mapActions } from 'vuex';
import PeoplePicker from '../../../../components/PeoplePicker/PeoplePicker.vue';
import ContactService from '../../../../services/ContactService';

export default {
  name: 'Contacts',
  components: {
    PeoplePicker
  },
  data() {
    return {
      internalContacts: [],
      externalContacts: [],
      showForm: false,
      type: 'INTERNAL',
      form: {
        user: {
          firstName: '',
          lastName: '',
          email: '',
          isExternal: '',
          displayName: '',
          unitName: ''
        },
        role: undefined,
        subscribe: true
      },
      roles: undefined,
      internalContactContainers: [],
      externalContactContainers: []
    };
  },
  async created() {
    this.roles = await this.loadRoles();
    const contacts = await ContactService.getBySupplierId(this.$route.params.id);

    this.internalContacts = contacts.filter(contact => contact.type === 'INTERNAL');
    this.externalContacts = contacts.filter(contact => contact.type === 'EXTERNAL');
  },
  methods: {
    ...mapActions(['loadRoles']),
    async saveContact() {
      // TODO subscription to changes if checked
      const contact = {
        type: this.type,
        supplierId: this.getClickedSupplier.id,
        roleId: this.form.role.id,
        user:
          this.type === 'EXTERNAL'
            ? {
              firstName: this.form.user.firstName,
              lastName: this.form.user.lastName,
              email: this.form.user.email,
              displayName: `${this.form.user.firstName} ${this.form.user.lastName}`,
              isExternal: 1
            }
            : this.form.user
      };
      await ContactService.createContact(contact).then(data => {
        (this.type === 'INTERNAL' ? this.internalContacts : this.externalContacts).push(data);
        this.showForm = false;
      });
      this.form.user = {
        firstName: '',
        lastName: '',
        email: '',
        isExternal: '',
        displayName: '',
        unitName: ''
      };
      this.form.role = undefined;
    },
    async removeContact(contactId) {
      await ContactService.deleteContact(contactId);

      this.externalContacts = this.externalContacts.filter(el => el.id !== contactId);
      this.internalContacts = this.internalContacts.filter(el => el.id !== contactId);
    },
    contractsToRoles(contracts) {
      return contracts.length > 0 ? contracts.map(el => el.role.name).filter((name, index) => contracts.map(el => el.role.name).indexOf(name) === index) : [];
    },
    toggleChevron(name, type) {
      const array = type === 'INTERNAL' ? this.internalContactContainers : this.externalContactContainers;
      if (array.indexOf(name) > -1) {
        array.splice(array.indexOf(name), 1);
        return;
      }
      array.push(name);
    }
  },
  computed: {
    ...mapGetters(['getActiveTab', 'getClickedSupplier']),
    checkInternalRoles() {
      return this.contractsToRoles(this.internalContacts);
    },
    checkExternalRoles() {
      return this.contractsToRoles(this.externalContacts);
    },
    internalRoles() {
      return this.roles.filter(el => el.types.includes('INTERNAL'));
    },
    externalRoles() {
      return this.roles.filter(el => el.types.includes('INTERNAL'));
    },
    canSave() {
      const result = !!this.form.role
        && !!this.form.role.id
        && !!this.form.user
        && !!this.form.user.firstName
        && this.form.user.firstName.trim().length > 0
        && !!this.form.user.lastName
        && this.form.user.lastName.trim().length > 0;
      return result;
    },
    isContainerOpen() {
      return (name, type) => (type === 'INTERNAL' ? this.internalContactContainers : this.externalContactContainers).indexOf(name) <= -1;
    }
  }
};
