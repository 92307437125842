/* eslint-disable import/no-cycle */

import SupplierService from '../../services/SupplierService';
import TagService from '../../services/TagService';

import Filter from './Filter';

const PAGE_SIZE = 21;

/* eslint-disable no-param-reassign */
const state = {
  clickedSupplier: null,
  suppliers: [],
  pageOffSet: 0,
  importantTags: undefined,
  total: undefined,
  segmentConflictError: false,
  loading: false,
  loadingMore: false
};

const getters = {
  getClickedSupplier($state) {
    return $state.clickedSupplier;
  },
  getSuppliers($state) {
    return $state.suppliers;
  },
  hasSegmentConflictError($state) {
    return $state.segmentConflictError;
  },
  isLoading($state) {
    return $state.loading;
  },
  isLoadingMore($state) {
    return $state.loadingMore;
  }
};

const mutations = {
  setOffset($state, offset) {
    $state.pageOffset = offset;
  },
  setTotal($state, total) {
    $state.total = total;
  },
  setLoadingMore($state, loading) {
    $state.loadingMore = loading;
  },
  setLoading($state, loading) {
    $state.loading = loading;
  },
  setSuppliers($state, suppliers) {
    $state.suppliers = suppliers;
  },
  setSegmentConflictError($state, error) {
    $state.segmentConflictError = error;
  },
  setClickedSupplier($state, supplier) {
    if ($state.suppliers.filter(el => el.id === supplier.id).length === 0) {
      $state.clickedSupplier = supplier;
      return;
    }
    // eslint-disable-next-line prefer-destructuring
    $state.clickedSupplier = $state.suppliers.filter(el => el.id === supplier.id)[0];
  }
};

const actions = {
  async loadSuppliers({ state: currentState, commit, rootState }) {
    commit('setLoading', true);
    const { results, total } = await SupplierService.getSuppliers(rootState.Filter.filterType, rootState.Filter.searchQuery, PAGE_SIZE, 0);
    commit('setTotal', total);
    commit('setSuppliers', [...results]);
    commit('setOffset', currentState.suppliers.length);
    commit('setLoading', false);

    return results;
  },
  async loadMoreSuppliers({ state: currentState, commit }) {
    if (currentState.loading || currentState.loadingMore) {
      return Promise.resolve();
    }

    if (currentState.total <= currentState.suppliers.length) {
      return Promise.resolve();
    }
    commit('setLoadingMore', true);

    const { results, total } = await SupplierService.getSuppliers(Filter.state.filterType, Filter.state.searchQuery, PAGE_SIZE, currentState.pageOffset);
    commit('setTotal', total);
    commit('setSuppliers', [...currentState.suppliers, ...results]);
    commit('setOffset', currentState.suppliers.length);
    commit('setLoadingMore', false);

    return results;
  },
  async loadImportantTags({ state: currentState }) {
    if (currentState.importantTags) {
      return currentState.importantTags;
    }
    currentState.importantTags = TagService.getImportantTags();
    return currentState.importantTags;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
