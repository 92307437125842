import { mapGetters } from 'vuex';
import measureService from '../../../../services/MeasureService';
import ModalFormMeasures from './ModalFormMeasures/ModalFormMeasures.vue';
import MeasureContainer from './MeasureContainer/MeasureContainer.vue';

export default {
  name: 'Measure',
  components: {
    ModalFormMeasures,
    MeasureContainer
  },
  data() {
    return {
      showAddMeasureModal: false,
      measureObjectToEdit: null,
      measures: [],
      actionTypes: [
        { value: 'Level1', name: 'Level 1: Shared Feedack with Supplier / Partner' },
        { value: 'Level2', name: 'Level 2: Discussed in Meeting' },
        { value: 'Level3', name: 'Level 3: Goal Agreement with agreed Measures' },
        { value: 'Level4', name: 'Level 4: Escalation & Claim' },
        { value: 'Other', name: 'Others' }
      ]
    };
  },
  async created() {
    this.measures = await measureService.getBySupplierId(this.getClickedSupplier.id);
  },
  methods: {
    toggleAddMeasureModal() {
      this.showAddMeasureModal = !this.showAddMeasureModal;
    },
    setMeasuretoEdit(measureObject = null) {
      this.measureObjectToEdit = measureObject;

      this.toggleAddMeasureModal();
    },
    async createMeasure(payload) {
      const res = await measureService.createMeasure(this.getClickedSupplier.id, payload);

      this.measures = [res, ...this.measures];
      this.toggleAddMeasureModal();
    },
    editMeasure(payload) {
      measureService.updateMeasure(payload).then(res => {
        const index = this.measures.findIndex(measure => measure.id === payload.id);
        this.measures.splice(index, 1, res);
        this.toggleAddMeasureModal();
      });
    },
    onMeasureDeleted(measureId) {
      this.measures = this.measures.filter(measure => measure.id !== measureId);
    }
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),
    getMeasures() {
      return this.measures;
    }
  }
};
