import httpService from './httpService';

const getInformationBySupplier = async (supplierId) => {
  const { data } = await httpService.get(`/information/supplier/${supplierId}`);
  return data;
};

const getInformationHistory = async (supplierId) => {
  const { data } = await httpService.get(`/information/supplier/${supplierId}/history`);
  return data;
};

const saveInformation = async (information, supplierId) => {
  let data;
  if (!information || !information.id) {
    data = await httpService.post(`/information/supplier/${supplierId}`, information);
  } else {
    data = await httpService.put(`/information/${information.id}`, information);
  }
  return data.data;
};

export default {
  saveInformation,
  getInformationBySupplier,
  getInformationHistory,
};
