import Datepicker from 'vuejs-datepicker';
import { VueEditor } from 'vue2-editor';
import { mapGetters, mapMutations } from 'vuex';
import SupplierService from '../../../../../services/SupplierService';

export default {
  name: 'PopUp-Detail',
  components: {
    Datepicker,
    VueEditor
  },

  data: () => ({
    popUpState: 'new',
    user: {},
    loggedUser: null,
    popUp: {
      text: '',
      start: '',
      end: ''
    },
    isEdited: false,
    loaded: false,
    oldValue: {},
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [
        {
          list: 'ordered'
        },
        {
          list: 'bullet'
        }
      ]
    ],
    viewMode: ''
  }),
  watch: {
    popUp: {
      handler() {
        this.getClickedSupplier.popup = this.popUp;
        /* eslint-disable operator-linebreak */

        this.isEdited =
          this.popUp &&
          JSON.stringify(this.oldValue) !== JSON.stringify(this.popUp) &&
          this.popUp.start &&
          this.popUp.end &&
          this.popUp.text &&
          this.popUp.text.trim().length > 2 &&
          new Date(this.popUp.start) <= new Date(this.popUp.end);
        /* eslint-enable operator-linebreak */
      },
      deep: true
    }
  },
  beforeDestroy() {
    this.getClickedSupplier.popup = this.oldValue;
    this.popUp = this.oldValue;
  },
  async created() {
    const { id } = this.$route.params;
    if (!this.getClickedSupplier) {
      const supplier = await SupplierService.getSupplierById(id);
      this.setSupplier(supplier);
    }
    const { data: oldPopup } = await SupplierService.getPopUp(this.getClickedSupplier.id);
    if (typeof oldPopup !== 'object') {
      this.getClickedSupplier.popup = this.popUp;
    } else {
      this.getClickedSupplier.popup = oldPopup;
    }
    this.oldValue = Object.assign({}, this.getClickedSupplier.popup);
    this.checkViewMode();
    this.popUp = this.getClickedSupplier.popup;
    this.loaded = true;
  },
  methods: {
    ...mapMutations(['setClickedSupplier']),
    checkViewMode() {
      if (this.getClickedSupplier.popup === null || Object.keys(this.getClickedSupplier.popup).length === 3) {
        this.getClickedSupplier.popup = Object.assign({}, this.popUp);
        this.viewMode = 'create';
      } else {
        if (this.getClickedSupplier.popup === null) {
          this.viewMode = 'create';
        }
        this.viewMode = 'view';
      }
    },
    async createPopUp() {
      this.popUp = await SupplierService.createPopUp(this.getClickedSupplier.popup, this.getClickedSupplier.id).catch(() => {});
      this.popUpState = 'existing';
      this.viewMode = 'view';
      this.oldValue = JSON.stringify(this.popUp);
    },
    editPopUp() {
      this.viewMode = 'edit';
    },
    async updatePopUp() {
      this.popUp = {
        ...this.popUp,
        ...(await SupplierService.updatePopUp(this.getClickedSupplier.popup, this.getClickedSupplier.popup.id).catch(() => {}))
      };
      this.popUpState = 'existing';
      this.viewMode = 'view';
      this.oldValue = JSON.stringify(this.popUp);
    },
    async deletePopUp(e) {
      e.preventDefault();
      await SupplierService.deletePopUp(this.getClickedSupplier.popup.id);
      this.popUpState = 'new';
      this.viewMode = 'create';
      this.oldValue = '';
      this.popUp = {};
    },
    cancelPopUp(e) {
      e.preventDefault();
      this.getClickedSupplier.popup = this.oldValue;
      this.viewMode = 'view';
    }
  },
  computed: {
    ...mapGetters(['getActiveTab', 'getClickedSupplier'])
  }
};
