import { mapMutations, mapActions } from 'vuex';
import SupplierService from '../../services/SupplierService';

export default {
  name: 'Card',
  props: ['supplier'],
  data() {
    return {
      cklickedFollowing: false,
      toolTipContent: '',
      color: '858585',
      isFilled: false
    };
  },
  beforeMount() {
    // eslint-disable-next-line no-unused-expressions
    this.supplier.following ? (this.isFilled = true) : (this.isFilled = false);
  },
  created() {
    this.toolTipContent = this.supplier.following ? 'Unsubscribe to stop being notified about updates to this supplier.' : 'Subscribe to be notified about updates from to supplier.';
    this.loadImportantTags().then(importantTags => {
      const coloredTags = this.supplier.tags.filter(tag => importantTags.filter(importantTag => tag.name === importantTag.name).length > 0);
      if (coloredTags.length > 0) {
        this.color = coloredTags[0].color;
      }
    });
  },
  methods: {
    ...mapMutations(['setClickedSupplier', 'setFilterType', 'setSearchQuery']),
    ...mapActions(['searchTag', 'loadImportantTags']),
    async toggleFollow() {
      // eslint-disable-next-line
      event.preventDefault();
      this.supplier.following = !this.supplier.following;
      const supplierInfo = {
        supplierId: this.supplier.id,
        follow: this.supplier.following
      };
      await SupplierService.toggleFollowSupplier(supplierInfo);
      this.cklickedFollowing = this.supplier.following;
    },
    mouseOverStar() {
      // eslint-disable-next-line no-unused-expressions
      if (!this.supplier.following) { (this.isFilled = !this.isFilled); }
    },
    clickedTag(tagVal) {
      this.searchTag(tagVal);
    }
  },
  computed: {
    spendLatsYear() {
      return this.supplier.previousYearSpentRange;
    }
  },
  watch: {
    supplier: {
      handler() {
        this.toolTipContent = this.supplier.following ? 'Unsubscribe to stop being notified about updates to this supplier.' : 'Subscribe to be notified about updates from to supplier.';
      },
      deep: true
    }
  }
};
