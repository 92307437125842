/* eslint-disable no-return-assign */

import { mapGetters, mapMutations } from 'vuex';
import Footer from '@/components/Footer/Footer.vue';
import InformationService from '../../services/InformationService';
import SupplierService from '../../services/SupplierService';

export default {
  name: 'history',
  components: {
    Footer,
  },
  data: () => ({
    notes: [],
    changes: [],
  }),
  async created() {
    const { id } = this.$route.params;
    if (!this.getClickedSupplier) {
      const supplier = await SupplierService.getSupplierById(id);
      this.setClickedSupplier(supplier);
    }

    if (!this.getClickedSupplier.informationHistory) {
      this.getClickedSupplier.informationHistory = await InformationService.getInformationHistory(
        this.getClickedSupplier.id,
      );
    }

    this.changes = this.getClickedSupplier.informationHistory;
  },
  methods: {
    ...mapMutations(['setClickedSupplier']),
    goBack() {
      this.$router.back();
    },
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),
  },
  watch: {
    'getClickedSupplier.informationHistory': {
      handler(val) {
        this.changes = val;
      },
      deep: true,
    },
  },
};
