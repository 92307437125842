/* eslint-disable */
import userService from '../../services/UserService';

export default {
  name: 'PeoplePicker',
  props: {
    label: String | 'People search',
    value: Object | String,
    disabled: Boolean | false,
    placeholder: String | undefined,
    peopleId: String | `people-${Math.floor(Math.random() * Math.floor(100000))}`
  },
  model: {
    prop: 'value',
    event: 'change'
  },

  data() {
    return {
      choices: [],
      minChars: 3,
      searchInput: '',
      error: false,
      currentPromiseIndex: 0,
      selectedUser: null,
      selectedUsers: [],
      autoCompleteId: `autocomplete-${Math.floor(Math.random() * Math.floor(100000))}`,
      isDisabled: false,
      multipleSelection: false
    };
  },

  watch: {
    selectedUser() {
      // console.log('selectedUser was changed');
      this.searchInput = this.selectedUser.displayName;
    },
  },

  async mounted() {
    this.multipleSelection = this.$el.attributes.getNamedItem('multiple') != null ? true : false;
    if (this.value) {
      // console.log('initialized', this.peopleId, 'with', this.value);
      if (this.multipleSelection) {
        const users = this.value;
        users.map(user => user.displayName = user.firstName + ' ' + user.lastName + ', ' + user.unitName);
        this.selectedUsers = users;
      } else {
        const user = this.value;
        user.displayName = user.firstName !== '' ? user.firstName + ' ' + user.lastName + ', ' + user.unitName : null;
        this.selectedUser = user;
      }
    }
  },

  methods: {
    searchUsers() {
      this.currentPromiseIndex++;
      const index = this.currentPromiseIndex;
      if (this.searchInput.length >= this.minChars) {
        this.error = false;
        userService.searchUsers(this.searchInput).then(data => {
          if (index == this.currentPromiseIndex) {
            this.choices = [];
            this.choices = data;
            this.choices.map(choice => choice.displayName = choice.firstName + ' ' + choice.lastName + ', ' + choice.unitName);
            const regex = new RegExp(this.searchInput, 'gi');
            this.choices.map(choice => choice.displayLabel = choice.displayName.replace(regex, '<strong>$&</strong>'));
            if (this.multipleSelection) {
              this.choices = this.choices.filter(newUser => this.selectedUsers.filter(existingUser => newUser.username === existingUser.username).length === 0);
            }
          }
        });
      } else {
        this.choices = [];
      }
    },

    addUser(user) {
      // console.log('add', user);
      delete user.displayLabel;
      this.choices = [];
      if (this.multipleSelection) {
        this.searchInput = null;
        this.selectedUsers.push(user);
        this.$emit('change', this.selectedUsers);
      } else {
        this.selectedUser = user;
        this.$emit('change', this.selectedUser);
      }
    },

    removeUser(index) {
      // console.log('remove', this.selectedUsers[index]);
      this.selectedUsers.splice(index, 1);
    },
  },
};
