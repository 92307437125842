import { mapMutations } from 'vuex';
import Tooltip from '../StarTooltip/StarTooltip.vue';
import DescriptionToolTip from '../DescriptionToolTip/DescriptionToolTip.vue';

export default {
  name: 'StarRating',
  components: { Tooltip, DescriptionToolTip },
  props: {
    Rating: Object,
    Editable: Boolean
  },
  data() {
    return {
      starsHovered: this.Rating.number,
      showTooltipId: -1,
      showDescriptionToolTip: false,
      tempHover: 0
    };
  },
  methods: {
    ...mapMutations(['setRatingFilter']),
    markStar(index) {
      if (!this.Editable) return;

      // eslint-disable-next-line
      this.starsHovered === index + 1 ? (this.starsHovered = 0) : (this.starsHovered = index + 1);

      this.$emit('input', this.starsHovered);
    },
    hoverStar(index) {
      if (!this.Editable) return;
      this.tempHover = index + 1;
      this.showTooltipId = index;
    },
    leaveStar() {
      this.tempHover = this.starsHovered;
      this.showTooltipId = -1;
    },
    hoverDescription() {
      this.showDescriptionToolTip = true;
    },
    leaveDescription() {
      this.showDescriptionToolTip = false;
    }
  }
};
