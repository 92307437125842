import { mapGetters, mapMutations } from 'vuex';
import SegmentForm from './Segment-Form/Segment-Form.vue';
import ConfirmSegmentDelete from './Confirm-Segment-Delete/Confirm-Segment-Delete.vue';
import ValueChainStepExplanation from './ValueChainStepExplanation.vue';

import SegmentService from '../../../../services/SegmentService';

const EDIT_SEGMENT_WHITELIST = ['GBS-SCM'];

export default {
  name: 'Segments',
  components: {
    SegmentForm,
    ConfirmSegmentDelete,
    ValueChainStepExplanation
  },
  data: () => ({
    isLoading: false,
    segments: [],
    showEditModal: false,
    showDeleteModal: false,
    segmentToEdit: null,
    segmentToDelete: null,
    availableValueChainStages: []
  }),
  async created() {
    this.isLoading = true;
    [this.availableValueChainStages, this.supplier] = await Promise.all([
      SegmentService.getAvailableValueChainStages(),
      (this.segments = await SegmentService.getBySupplierId(this.getClickedSupplier.id))
    ]);
    this.isLoading = false;
  },
  methods: {
    ...mapMutations(['setSegmentConflictError']),
    toggleEditModal() {
      this.showEditModal = !this.showEditModal;
    },
    toggleDeleteModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    resetSegmentEdit() {
      this.segmentToEdit = null;
      this.toggleEditModal();
    },
    editSegment(segmentId) {
      const segment = this.segments.find(s => s.id === segmentId);
      if (segment) {
        const segmentFormdata = {
          id: segment.id,
          name: segment.segment.name,
          partnershipStatus: segment.partnershipStatus,
          valueChainStages: segment.valueChainStages,
          exitAndLegacyDate: segment.exitAndLegacyDate,
          exitDateSpecified: segment.exitDateSpecified
        };
        this.segmentToEdit = segmentFormdata;
        this.toggleEditModal();
      }
    },
    askSegmentDeletion(segment) {
      this.segmentToDelete = segment;
      this.toggleDeleteModal();
    },
    resetSegmentDelete() {
      this.segmentToDelete = null;
      this.toggleDeleteModal();
    },
    async deleteSegment() {
      await SegmentService.deleteById(this.getClickedSupplier.id, this.segmentToDelete.id);
      this.segments = this.segments.filter(item => item.id !== this.segmentToDelete.id);
      this.toggleDeleteModal();
      this.segmentToDelete = null;
    },
    async segmentSaved({ payload }) {
      try {
        let response;

        if (!this.segmentToEdit) {
          response = await SegmentService.createSegment(this.getClickedSupplier.id, payload);
        } else {
          response = await SegmentService.updateSegment(this.getClickedSupplier.id, this.segmentToEdit.id, payload);
        }

        const oldItemIndex = this.segments.findIndex(item => item.id === response.id);
        if (this.segmentToEdit && oldItemIndex !== undefined) {
          const newSegments = this.segments.map((s, i) => {
            if (i === oldItemIndex) {
              return response;
            }
            return s;
          });
          this.segments = newSegments;
        } else {
          this.segments = [response, ...this.segments];
        }
        this.segmentToEdit = null;
        this.toggleEditModal();
      } catch (err) {
        if (err.response.status === 409) {
          this.setSegmentConflictError(true);
        }
      }
    },
    formatDate(date) {
      if (date) {
        return date.slice(0, 4);
      }
      return null;
    }
  },
  computed: {
    ...mapGetters(['getClickedSupplier', 'getCurrentUser']),
    orderedSegments() {
      return this.segments
        .sort((a, b) => a.partnershipStatus.order - b.partnershipStatus.order)
        .map(segment => ({
          ...segment,
          valueChainStages: segment.valueChainStages.sort((a, b) => a.order - b.order)
        }));
    },
    canViewActions() {
      return segment => {
        const { createdBy } = segment;

        if (!createdBy || !this.getCurrentUser || !this.getCurrentUser.id) {
          return false;
        }

        // Allow creator to see edit / delete actions
        if (createdBy.unitName.toLowerCase() === this.getCurrentUser.unitName.toLowerCase()) {
          return true;
        }

        // Allow certain units to see edit / delete actions
        if (this.getCurrentUser.unitName.includes(EDIT_SEGMENT_WHITELIST)) {
          return true;
        }

        return false;
      };
    }
  }
};
