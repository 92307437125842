/* eslint-disable import/no-cycle */
import httpService from './httpService';

const getUser = async () => {
  const { data } = await httpService.get('/users');
  return data;
};

const getUserById = async id => {
  const { data } = await httpService.get(`/users/${id}`);
  return data;
};

const searchUsers = async query => {
  const { data } = await httpService.get(`/users/search?search=${query}`);
  return data;
};

const getCurrent = async () => {
  const { data } = await httpService.get('/users/me');
  return data;
};

const UserService = {
  getUser,
  getUserById,
  searchUsers,
  getCurrent
};

export default UserService;
