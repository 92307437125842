import { mapGetters } from 'vuex';

export default {
  name: 'Obligo',
  methods: {},
  computed: {
    ...mapGetters(['getClickedSupplier']),
    widgetUrl() {
      return `https://tableau1.swisscom.com/t/FCO/views/Widgets_Obligo/ObligonachKostenart?%3Aembed=y&Lieferant=${encodeURI(this.getClickedSupplier.name)}`;
    }
  }
};
