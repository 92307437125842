import { mapGetters, mapMutations, mapActions } from 'vuex';
import contactService from '../../../../services/ContactService';
import ratingService from '../../../../services/RatingService';
import AddRatingModal from './ModalFormRating/ModalFormRating.vue';
import AverageRating from './AverageRating/AverageRating.vue';
import RatingContainer from './RatingContainer/RatingContainer.vue';

const DOWNLOAD_RATINGS_WHITELIST = ['GBS-SCM'];

export default {
  name: 'Rating',
  components: { 'add-rating-modal': AddRatingModal, AverageRating, RatingContainer },
  data() {
    return {
      ratings: [],
      categories: [],
      showAddRatingModal: false,
      isPurchasingManager: false
    };
  },
  async created() {
    const { purchasingOrderNumber } = this.$route.query;
    if (purchasingOrderNumber) {
      this.showAddRatingModal = true;
    }
    this.loadCurrentUser();
    this.fetchData();
    this.isPurchasingManager = await this.canDownloadRatings();
  },
  methods: {
    ...mapActions(['loadCurrentUser']),
    ...mapMutations(['unsetRatingFilter']),
    async fetchData() {
      const [res, categories] = await Promise.all([ratingService.getBySupplierId(this.getClickedSupplier.id), ratingService.getCategories()]);
      this.ratings = res;

      this.categories = categories;
    },
    async canDownloadRatings() {
      if (this.getCurrentUser.unitName.includes(DOWNLOAD_RATINGS_WHITELIST)) {
        return true;
      }

      if (await contactService.isPurchasingManager(this.getClickedSupplier.id, this.getCurrentUser.username)) {
        return true;
      }

      return false;
    },
    toggleAddRatingModal() {
      this.showAddRatingModal = !this.showAddRatingModal;
    },
    async downloadAllRatings() {
      await ratingService.getFormattedRatings(this.getClickedSupplier.id, this.getClickedSupplier.name);
    },
    async createRating(ratingData) {
      const newItem = await ratingService.createRating(this.getClickedSupplier.id, ratingData);
      this.toggleAddRatingModal();
      this.ratings = [newItem, ...this.ratings];
    },
    onRatingDeleted(ratingId) {
      this.ratings = this.ratings.filter(rating => rating.id !== ratingId);
    },
    onRatingEdited() {
      // Reload ratings when one is updated
      this.fetchData();
    },
    starIcon(i, average) {
      if (i + 1 > average && i < average) {
        return 'icon-star-half';
      }
      if (i + 1 <= average) {
        return 'icon-star-filled';
      }
      return 'icon-star';
    }
  },
  computed: {
    ...mapGetters(['getRatingFilters', 'getClickedSupplier', 'getCurrentUser']),
    filteredRatings() {
      // Return all ratings if no filters are given
      if (this.getRatingFilters.length === 0) {
        return this.ratings;
      }

      // Check if there's any score in the ratings with the given score name
      return this.ratings.filter(rating => rating.ratingScores.some(score => this.getRatingFilters.includes(score.name) && score.value > 0));
    },
    mappedAverageRatings() {
      // Prepare all scores together in an array
      const allRatingScores = this.ratings.reduce((acc, rating) => [...acc, ...rating.ratingScores], []);
      const result = this.categories.map(categoryName => {
        const categoryScores = allRatingScores.filter(score => score.category === categoryName);
        const mappedScores = categoryScores.reduce((categoryObject, score) => {
          // eslint-disable-next-line no-param-reassign
          categoryObject[score.name] = categoryObject[score.name] ? [...categoryObject[score.name], score.value] : [score.value];

          return categoryObject;
        }, {});

        return {
          scores: mappedScores,
          name: categoryName
        };
      });

      const simplified = result.filter(r => r.name === 'Simplified')[0];

      simplified.scores = {
        Quality: simplified.scores.Quality,
        Costs: simplified.scores.Costs,
        Innovation: simplified.scores.Innovation,
        Relationship: simplified.scores.Relationship
      };

      return simplified;
    },
    overallAverageRating() {
      const allRatingScores = this.ratings
        .reduce((acc, rating) => [...acc, ...rating.ratingScores], [])
        .map(r => r.value)
        .filter(score => score !== 0);

      const averageRating = allRatingScores.reduce((acc, score) => acc + score, 0) / allRatingScores.length;

      return (Math.ceil((averageRating * 10) / 5) * 5) / 10;
    },
    recommendationAverage() {
      const recommendations = this.ratings.map(r => r.recommendation).filter(f => f !== null);

      if (recommendations.length === 0) {
        return 'No Ratings';
      }

      return `${Math.ceil(((100 / recommendations.length) * recommendations.filter(r => r === true).length) / 5) * 5} %`;
    }
  }
};
