// eslint-disable-next-line import/no-cycle
import UserService from '../../services/UserService';

/* eslint-disable no-param-reassign */
const state = {
  currentUser: { id: null }
};

const getters = {
  getCurrentUser($state) {
    return $state.currentUser;
  }
};

const mutations = {
  setCurrentUser($state, userData) {
    $state.currentUser = userData;
  }
};

const actions = {
  async loadCurrentUser({ commit }) {
    const userData = await UserService.getCurrent();
    commit('setCurrentUser', userData);

    return userData;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
