import { mapMutations } from 'vuex';

export default {
  name: 'AverageRating',
  props: { Scores: Object, CategoryTitle: String },
  computed: {
    mappedAverages() {
      return Object.entries(this.Scores).map(([key, values]) => {
        // Filter out all values that are 0
        const zeroFilteredValues = values.filter(val => val !== 0);

        // Calculate the average value of all non-zero values
        const value = values.reduce((acc, val) => acc + val, 0) / zeroFilteredValues.length;

        let roundedValue = (Math.ceil((value * 10) / 5) * 5) / 10;

        if (zeroFilteredValues.length === 0) {
          roundedValue = 0;
        }
        return { value: roundedValue, name: key, count: zeroFilteredValues.length };
      });
    }
  },
  methods: {
    ...mapMutations(['setRatingFilter']),
    setFilter(f) {
      this.setRatingFilter(f);
    },
    starIcon(i, average) {
      if (i + 1 > average.value && i < average.value) {
        return 'icon-star-half';
      }
      if (i + 1 <= average.value) {
        return 'icon-star-filled';
      }
      return 'icon-star';
    }
  }
};
