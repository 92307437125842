import measureService from '../../../../../services/MeasureService';
import ConfirmationModal from '../../../ConfirmationModal/ConfirmationModal.vue';

export default {
  name: 'MeasureContainer',
  props: { Measure: Object, actionTypes: Array },

  components: { ConfirmationModal },
  data() {
    return {
      showDeleteModal: false
    };
  },
  methods: {
    editMeasure() {
      this.$emit('edit', this.Measure);
    },
    toggleDeleteMeasureModal() {
      this.showDeleteModal = !this.showDeleteModal;
    },
    async deleteMeasure() {
      await measureService.deleteMeasure(this.Measure.id);
      this.$emit('delete', this.Measure.id);
      this.toggleDeleteMeasureModal();
    }
  },
  computed: {
    userIsAllowed() {
      return !this.Measure.isConfidential || this.Measure.userIsAllowed;
    },
    actionTypeName() {
      const { name } = this.actionTypes.find(type => type.value === this.Measure.type);
      return name;
    }
  }
};
