// eslint-disable-next-line import/no-cycle
import httpService from './httpService';

const add = async (supplierId, name) => {
  const { data } = await httpService.post(`/tags/${name}/supplier/${supplierId}`);

  return data;
};

const remove = async (supplierId, tagId) => {
  const { data } = await httpService.delete(`/tags/${tagId}/supplier/${supplierId}`);
  return data;
};

const getImportantTags = async () => {
  const { data } = await httpService.get('/tags/important');
  return data;
};

const TagService = {
  add,
  remove,
  getImportantTags,
};

export default TagService;
