/* eslint-disable no-param-reassign */
const state = {
  ratingFilters: []
};

const getters = {
  getRatingFilters($state) {
    return $state.ratingFilters;
  }
};

const mutations = {
  setRatingFilter($state, filterName) {
    // Only add item if it's not already added
    if (!$state.ratingFilters.includes(rating => rating === filterName)) {
      $state.ratingFilters.push(filterName);
    }
  },
  unsetRatingFilter($state, filterName) {
    $state.ratingFilters = $state.ratingFilters.filter(filter => filter !== filterName);
  }
};

const actions = {};

export default {
  state,
  getters,
  mutations,
  actions
};
