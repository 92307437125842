import { urlMatches } from '../../../../../util/urlMatches';

const filterEmptyUrl = ({ name, url }) => (name || '').length > 0 && (url || '').length > 0;

export default {
  name: 'ModalFormMeasures',
  props: {
    Measure: Object,
    actionTypes: Array
  },
  data() {
    return {
      measure: null,
      publicPath: process.env.BASE_URL,
      measureEmptyState: {
        title: '',
        type: '',
        description: '',
        externalFiles: [{ name: '', url: '' }],
        isConfidential: false
      },
      touchedUrlFields: []
    };
  },
  created() {
    // eslint-disable-next-line no-unused-expressions
    this.Measure ? (this.measure = this.Measure) : (this.measure = this.measureEmptyState);
  },
  methods: {
    saveMeasure() {
      if (!this.isFormValid) {
        return;
      }

      // Make sure that only correctly filled urls are saved
      const externalFiles = this.measure.externalFiles.filter(filterEmptyUrl);

      const measureObject = {
        ...this.measure,
        externalFiles
      };

      const emitAction = this.Measure ? 'edit' : 'create';
      this.$emit(emitAction, measureObject);
    },
    cancel() {
      this.$emit('close');
    },
    addDocument() {
      this.measure.externalFiles.push({ name: '', url: '' });
    },
    getActionType() {
      return this.measure.type || '';
    },
    removeDocument(fileIndex) {
      this.measure.externalFiles.splice(fileIndex, 1);
    },
    onChange(property, value) {
      this.measure[property] = value;
    },
    isValidUrl(value) {
      return urlMatches(value);
    },
    onUrlFieldTouched(fieldIndex) {
      if (!this.touchedUrlFields.includes(fieldIndex)) {
        this.touchedUrlFields.push(fieldIndex);
      }
    }
  },
  computed: {
    isFormValid() {
      // Make sure that all non-empty urls are valid
      const areUrlsValid = this.measure.externalFiles.filter(filterEmptyUrl).every(item => this.isValidUrl(item.url));
      return this.measure.title && this.measure.type && areUrlsValid;
    }
  }
};
