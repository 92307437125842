<template>
  <span>Logging in...</span>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: 'OidcCallback',
  methods: {
    ...mapActions('oidcStore', ['oidcSignInCallback'])
  },
  created() {
    this.oidcSignInCallback()
      .then(redirectPath => {
        this.$router.push(redirectPath);
      })
      .catch(err => {
        // eslint-disable-next-line
        console.error(err);
        this.$router.push('/'); // Handle errors any way you want
      });
  }
};
</script>
