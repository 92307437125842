// eslint-disable-next-line import/no-cycle
import httpService from './httpService';

const getRoles = async () => {
  const { data } = await httpService.get('/roles');
  return data;
};

export default {
  getRoles,
};
