import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'OverviewHeader',
  data() {
    return {
      query: '',
      isSearch: false,
    };
  },
  created() {
    this.query = this.getSearchQuery;
  },
  methods: {
    ...mapMutations(['setFilterType', 'setSearchQuery']),
    ...mapActions(['loadSuppliers']),
    setFilter(filterType) {
      this.setFilterType(filterType);
      if (this.query.trim().length > 0) {
        this.loadSuppliers();
      }
    },
    resetSearch() {
      this.setSearchQuery('');
    },
    openFeedback() {
      window.open('https://forms.office.com/Pages/ResponsePage.aspx?id=h1tONsfBDUKb7sNdGbVXoT9gPhz7EnZJtJBWafN7oUVUM01TUVJXU1MzSEExQU9NWlc3VTlWM0czRS4u');
    },
    // eslint-disable-next-line func-names
  },
  computed: {
    ...mapGetters(['getFilterType', 'getSearchQuery']),
  },
  watch: {
    query(val) {
      this.setSearchQuery(val);
      this.loadSuppliers();
    },
    getSearchQuery(val) {
      this.query = val;
    },
  },
};
