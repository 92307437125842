import { mapGetters } from 'vuex';
import SegmentService from '../../services/SegmentService';

export default {
  name: 'SegmentLandscape',
  data: () => ({
    isLoading: false,
    suppliers: [],
    valueChainStages: [],
    partnershipStates: [],
    expandedCells: [],
    segment: {}
  }),
  async mounted() {
    this.isLoading = true;
    const segmentId = this.$route.params.id;
    const [suppliers, valueChainStages, partnershipStates, segmentInfo] = await Promise.all([
      SegmentService.findSuppliersBySegmentId(segmentId),
      SegmentService.getAvailableValueChainStages(),
      SegmentService.getAvailablePartnershipStates(),
      SegmentService.getById(segmentId)
    ]);

    this.suppliers = this.calculateSortOrder(suppliers);
    this.valueChainStages = valueChainStages;
    this.partnershipStates = partnershipStates;
    this.segment = segmentInfo;
    this.isLoading = false;
  },
  methods: {
    /**
     * Returns totalCount of suppliers for a cell and all suppliers themselves.
     * @param {string} valueChainStageId
     * @param {string} partnershipStatusId
     * @param {boolean?} returnTotal Boolean to check if all items are shown or not
     */
    getSuppliersForCell(valueChainStageId, partnershipStatusId, returnTotal = false) {
      const suppliers = this.suppliers.filter((s) => {
        const hasSamePartnershipStatus = s.partnershipStatus.id === partnershipStatusId;
        const hasSameValueChainStage = s.valueChainStages.some(v => v.id === valueChainStageId);
        return hasSamePartnershipStatus && hasSameValueChainStage;
      });

      const sortedSuppliers = suppliers.sort((a, b) => b.count - a.count);

      if (this.isCellExpanded(valueChainStageId, partnershipStatusId) && !returnTotal) {
        return [sortedSuppliers.length, sortedSuppliers];
      }
      return [sortedSuppliers.length, sortedSuppliers.slice(0, 3)];
    },
    calculateSortOrder(suppliers) {
      // Calculate the count of occurrences of one supplier in the landscape
      // in order to sort them descending
      return suppliers.map((supplier) => {
        // Count of occurrences of current supplier
        const valueChainStagesCount = supplier.valueChainStages.length;

        // Count of occurrences of same supplier in other suppliers
        const otherOccurrences = suppliers.filter(s => s.id !== supplier.id && s.supplier.id === supplier.supplier.id);
        const otherOccurrencesCount = otherOccurrences.reduce((acc, curr) => acc + curr.valueChainStages.length, 0);

        return {
          ...supplier,
          count: valueChainStagesCount + otherOccurrencesCount
        };
      });
    },
    addExpandedCell(valueChainStageId, partnershipStatusId) {
      this.expandedCells.push({ valueChainStageId, partnershipStatusId });
    },
    isCellExpanded(valueChainStageId, partnershipStatusId) {
      return this.expandedCells.some(a => a.valueChainStageId === valueChainStageId && a.partnershipStatusId === partnershipStatusId);
    },
    backAction() {
      if (!this.getClickedSupplier) {
        this.$router.push('/');
      } else {
        this.$router.go(-1);
      }
    }
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),
    backText() {
      if (!this.getClickedSupplier) {
        return 'home';
      }

      return 'supplier';
    }
  }
};
