class LatestPromiseStack {
  constructor() {
    this.list = [];
    this.resolve = null;
    this.reject = null;

    this.initialize = () => {
      this.promise = new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    };

    this.initialize();
  }

  getLatestPromise() {
    return new Promise((resolve, reject) => this.promise
      .then((result) => {
        this.initialize();
        return resolve(result);
      })
      .catch((error) => {
        this.initialize();
        return reject(error);
      }));
  }

  push(promise) {
    if (Promise.resolve(promise) !== promise) {
      return Promise.reject();
    }

    const resolveLast = (result) => {
      if (this.list.length === 0) {
        return;
      }

      const last = this.list.slice(-1)[0];
      if (last === promise) {
        this.list = [];
        this.resolve(result);
      }
    };

    this.list.push(promise);
    promise.then(resolveLast).catch(this.reject);
    return this.getLatestPromise();
  }

  clear() {
    this.list = [];
    this.initialize();
  }
}

export default LatestPromiseStack;
