/* eslint-disable no-param-reassign */

const state = {
  filterType: 'SUPPLIERS',
  searchQuery: '',
};

const getters = {
  getFilterType($state) {
    return $state.filterType;
  },
  getSearchQuery($state) {
    return $state.searchQuery;
  },
};

const mutations = {
  setFilterType($state, filterType) {
    $state.filterType = filterType;
  },
  setSearchQuery($state, query) {
    $state.searchQuery = query;
  },
};

const actions = {
  searchTag({ commit }, tag) {
    commit('setFilterType', 'TAGS');
    commit('setSearchQuery', tag.name);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
