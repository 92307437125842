import httpService from './httpService';

const mapPayloadForSaving = (ratingObj) => {
  const ratingScores = ratingObj.ratingScores.reduce((acc, category) => {
    const scores = category.ratingOptions.map((option) => ({
      name: option.name,
      value: option.value,
      category: category.category,
      id: option.id,
    }));

    return [...acc, ...scores];
  }, []);

  return {
    title: ratingObj.title,
    description: ratingObj.description,
    isConfidential: ratingObj.isConfidential,
    purchasingOrderNumber: ratingObj.purchasingOrderNumber,
    recommendation: ratingObj.recommendation,
    ratingScores,
  };
};

const getBySupplierId = async (supplierId) => {
  const { data } = await httpService.get(`/ratings/supplier/${supplierId}`);
  return data;
};

const getFormattedRatings = async (supplierId, supplierName) => {
  const { data } = await httpService.get(`/export/ratings/${supplierId}`);

  const buffer = Buffer.from(data.data);

  if (navigator.msSaveBlob) {
    navigator.msSaveBlob(new Blob([buffer], { type: 'text/csv;charset=utf-8;' }), `${supplierName}_Ratings.csv`);
  } else {
    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,\uFEFF, ${buffer}`);
    const link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', encodedUri);
      link.setAttribute('download', `${supplierName}_Ratings.csv`);
      document.body.appendChild(link); // Required for FF
      link.click();
      document.body.removeChild(link); // Required for FF
    }
  }

  return data;
};

const createRating = async (supplierId, payload) => {
  const { data } = await httpService.post(`/ratings/supplier/${supplierId}`, mapPayloadForSaving(payload));
  return data;
};

const updateRating = async (ratingId, payload) => {
  const { data } = await httpService.put(`/ratings/${ratingId}`, mapPayloadForSaving(payload));
  return data;
};

const deleteById = async (ratingId) => {
  await httpService.delete(`/ratings/${ratingId}`);
  return true;
};

const getCategories = async () => {
  const { data } = await httpService.get('/ratings/categories');
  return data;
};

export default {
  getBySupplierId,
  getFormattedRatings,
  updateRating,
  createRating,
  deleteById,
  getCategories,
};
