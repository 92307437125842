import { mapGetters, mapMutations, mapActions } from 'vuex';
import ClickOutside from 'vue-click-outside';

import SupplierService from '../../services/SupplierService';
import Contacts from './Tabs/Contacts/Contacts.vue';
import Info from './Tabs/Info/Info.vue';
import Contracts from './Tabs/Contracts/Contracts.vue';
import Obligo from './Tabs/Obligo/Obligo.vue';
import Spend from './Tabs/Spend/Spend.vue';
import PopUpElement from './Tabs/Popup/Popup.vue';
import Popup from './Tabs/Popup/Detail/Popup-Detail.vue';
import Footer from '../../components/Footer/Footer.vue';
import TagService from '../../services/TagService';
import Segments from './Tabs/Segments/Segments.vue';
import Measure from './Tabs/Measure/Measure.vue';
import Rating from './Tabs/Rating/Rating.vue';

export default {
  name: 'SupplierDetail',
  components: {
    Contacts,
    Info,
    Contracts,
    Obligo,
    PopUpElement,
    Spend,
    Popup,
    Segments,
    Measure,
    Rating,
    Footer
  },
  data() {
    return {
      lastRoute: null,
      isFilled: false,
      loaded: false,
      tagInput: '',
      isTagEditActive: false,
      tags: [],
      popUp: {
        text: '',
        start: '',
        end: ''
      },
      showPopUp: false,
      clickedFollowing: false,
      toolTipContent: '',
      showSpendPopup: false,
      showObligoPopup: false
    };
  },
  async created() {
    const { id } = await this.$route.params;

    // set tab by query param
    const tab = this.$route.query.t;
    if (tab) {
      this.setActiveTab(tab.toUpperCase());
    }
    if (!this.getClickedSupplier || this.getClickedSupplier.id !== id) {
      const supplier = await SupplierService.getSupplierById(id);
      this.setClickedSupplier(supplier);
    }
    // eslint-disable-next-line no-unused-expressions
    this.getClickedSupplier.following ? (this.isFilled = true) : (this.isFilled = false);

    this.loaded = true;
    this.preparePopup();
  },
  beforeRouteEnter(_, from, next) {
    next(vm => {
      if (from.name) {
        vm.lastRoute = from; /* eslint-disable-line */
      }
    });
  },
  methods: {
    ...mapMutations(['setActiveTab', 'setClickedSupplier', 'toggleTabDropdownExpand']),
    ...mapActions(['searchTag']),
    async preparePopup() {
      const { data: popup } = await SupplierService.getPopUp(this.getClickedSupplier.id);
      this.getClickedSupplier.popup = popup;
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      if (popup.text && popup.text !== '') {
        this.showPopUp = new Date(popup.start) <= now && now <= new Date(popup.end);
      } else {
        this.showPopUp = false;
      }
    },
    goBack() {
      if (this.lastRoute) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
    mouseOverStar() {
      // eslint-disable-next-line no-unused-expressions
      !this.getClickedSupplier.following ? (this.isFilled = !this.isFilled) : (this.isFilled = this.getClickedSupplier.following);
    },

    checkTab(tabName) {
      let tab = tabName === 'OBLIGO' ? (this.showObligoPopup = true) : false;
      tab = tabName === 'SPEND' ? (this.showSpendPopup = true) : false;
      return tab;
    },
    async toggleFollow() {
      this.getClickedSupplier.following = !this.getClickedSupplier.following;
      const supplierInfo = {
        supplierName: this.getClickedSupplier.name,
        follow: this.getClickedSupplier.following
      };
      await SupplierService.toggleFollowSupplier(supplierInfo);
    },
    focusTagEditInput() {
      this.$nextTick(() => {
        this.$refs.tagInputElement.focus();
      });
    },
    toggleTagEdit(saveOnToggle = false) {
      if (this.isTagEditActive && saveOnToggle) {
        this.addTag();
      }
      this.tagInput = '';
      this.isTagEditActive = !this.isTagEditActive;
      this.focusTagEditInput();
    },
    activateTagEdit() {
      // Force to activate tag edit
      this.isTagEditActive = true;
      this.focusTagEditInput();
    },
    async removeTag(supplierId, tagId) {
      await TagService.remove(supplierId, tagId);
      this.getClickedSupplier.tags = this.getClickedSupplier.tags.filter(item => item.id !== tagId);
    },
    async addTag() {
      if (this.tagInput.trim().length > 0) {
        await TagService.add(this.getClickedSupplier.id, this.tagInput).then(tag => {
          if (this.getClickedSupplier.tags.filter(el => el.id === tag.id).length === 0) {
            this.getClickedSupplier.tags.push(tag);
          }
        });
        this.tagInput = '';
      }
    },
    clickedTag(tag) {
      this.searchTag(tag);
      this.goBack();
    },
    changeTab(tabName) {
      this.$router.replace({
        query: {
          t: tabName.toLowerCase()
        }
      });
      this.setActiveTab(tabName);
    }
  },
  computed: {
    ...mapGetters(['getActiveTab', 'getAvailableDetailTabs', 'getClickedSupplier']),
    supplier() {
      return this.getClickedSupplier;
    }
  },
  watch: {
    getClickedSupplier: {
      handler() {
        this.toolTipContent = this.getClickedSupplier.following ? 'Unsubscribe to stop being notified about updates to this supplier.' : 'Subscribe to be notified about updates from to supplier.';
      },
      deep: true
    }
  },
  directives: {
    ClickOutside
  }
};
//
