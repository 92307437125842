import debounce from 'debounce-promise';

// eslint-disable-next-line import/no-cycle
import httpService from './httpService';
import LatestPromiseStack from './LatestPromiseStack';
// eslint-disable-next-line import/no-cycle
const latestPromiseStack = new LatestPromiseStack();

const getSuppliers = async (type, searchQuery, pageSize, pageOffset) => {
  const encodedQuery = encodeURIComponent(searchQuery);

  const promise = httpService.get(`/suppliers/search/${type}?search=${encodedQuery}&pageSize=${pageSize}&pageOffset=${pageOffset}`);
  const { data } = await latestPromiseStack.push(promise);
  return data;
};

const getSuppliersDebounced = debounce(getSuppliers, 350);

const getSupplierById = async id => {
  const { data } = await httpService.get(`/suppliers/${id}`);
  return data;
};

const toggleFollowSupplier = async supplier => {
  const { data } = await httpService.post('/suppliers/follow', supplier);
  return data;
};

const getPopUp = async supplierId => {
  const { data } = await httpService.get(`/popup/supplier/${supplierId}`);

  return { data };
};

const createPopUp = async (popup, supplierId) => {
  const { data } = await httpService.post(`/popup/supplier/${supplierId}`, popup).then(response => response);
  return data;
};

const updatePopUp = async (popup, popupId) => {
  const { data } = await httpService.put(`/popup/${popupId}`, popup).then(response => response);
  return data;
};

const deletePopUp = async popupId => {
  await httpService.delete(`/popup/${popupId}`).then(response => response);
};

const getSupplierHistory = async id => {
  const { data } = await httpService.get(`/information/supplier/${id}/history`);
  return data;
};

const SupplierService = {
  getSuppliers: getSuppliersDebounced,
  getSupplierById,
  toggleFollowSupplier,
  getPopUp,
  createPopUp,
  updatePopUp,
  deletePopUp,
  getSupplierHistory
};

export default SupplierService;
