// eslint-disable-next-line import/no-cycle
import RoleService from '../../services/RoleService';

/* eslint-disable no-param-reassign */
const state = {
  activeTab: 'CONTACTS',
  availableDetailTabs: [
    {
      name: 'CONTACTS',
      titel: 'Contacts',
      info: false
    },
    {
      name: 'INFO',
      titel: 'Info & CR/Risks',
      info: false
    },
    {
      name: 'CONTRACTS',
      titel: 'Contracts',
      info: false
    },
    {
      name: 'OBLIGO',
      titel: 'Obligo',
      info: true
    },
    {
      name: 'SPEND',
      titel: 'Spend',
      info: true
    },
    {
      name: 'POPUP',
      titel: 'Pop-up',
      info: false
    },
    {
      name: 'SEGMENTATION',
      titel: 'Segmentation',
      info: false
    },
    {
      name: 'SUPPLIERRATING',
      titel: 'Supplier Rating',
      info: false
    },
    {
      name: 'SUPPLIERDEVELOPMENT',
      titel: 'Supplier Development',
      info: false
    }
    // {
    //   name: 'MEASURES',
    //   expand: false,
    //   options: [
    //     { titel: 'Measures', name: 'MEASURES' },
    //     { name: 'RATINGFEEDBACK', titel: 'Rating & Feedback' }
    //   ],
    //   info: false,
    //   activeTab: 'Measures',
    //   dropdown: true
    // }
  ],
  contractFields: [
    {
      columnName: '',
      keyName: '',
      position: 0,
      class: 'ext-targetvalue',
      type: 'INTERNAL'
    }
  ],
  roles: undefined
};

const getters = {
  getActiveTab($state) {
    return $state.activeTab;
  },
  getAvailableDetailTabs($state) {
    return $state.availableDetailTabs;
  }
};

const mutations = {
  setActiveTab($state, tab) {
    $state.activeTab = tab;
  },
  toggleTabDropdownExpand($state, index) {
    $state.availableDetailTabs[index].expand = !$state.availableDetailTabs[index].expand;
  }
};

const actions = {
  async loadRoles({ state: $state }) {
    if ($state.roles) {
      return $state.roles;
    }
    $state.roles = RoleService.getRoles();
    return $state.roles;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
