import httpService from './httpService';

const getById = async (segmentId) => {
  const { data } = await httpService.get(`/segments/${segmentId}`);
  return data;
};

const search = async (query) => {
  const { data } = await httpService.get(`/segments?name=${query}`);
  return data;
};

const findSuppliersBySegmentId = async (segmentId) => {
  const { data } = await httpService.get(`/segments/${segmentId}/suppliersegments`);
  return data;
};

const getAvailableValueChainStages = async (...filterIds) => {
  const { data } = await httpService.get('/valuechainstages');

  return filterIds.length === 0 ? data : data.filter(item => filterIds.includes(item.id));
};

const getAvailablePartnershipStates = async () => {
  const { data } = await httpService.get('/partnershipstates');
  return data;
};

const createSegment = async (supplierId, payload) => {
  const { data } = await httpService.post(`/suppliers/${supplierId}/segments`, payload);
  return data;
};

const updateSegment = async (supplierId, supplierSegmentId, payload) => {
  const { data } = await httpService.put(`/suppliers/${supplierId}/segments/${supplierSegmentId}`, payload);
  return data;
};

const getBySupplierId = async (supplierId) => {
  const { data } = await httpService.get(`/suppliers/${supplierId}/segments`);
  return data;
};

const getSupplierSegmentById = async (supplierId, supplierSegmentId) => {
  const { data } = await httpService.get(`/suppliers/${supplierId}/segments/${supplierSegmentId}`);
  return data;
};
const deleteById = async (supplierId, supplierSegmentId) => {
  const { data } = await httpService.delete(`/suppliers/${supplierId}/segments/${supplierSegmentId}`);
  return data;
};

const SegmentService = {
  getBySupplierId,
  getById,
  search,
  createSegment,
  updateSegment,
  getAvailableValueChainStages,
  getAvailablePartnershipStates,
  findSuppliersBySegmentId,
  getSupplierSegmentById,
  deleteById,
};

export default SegmentService;
