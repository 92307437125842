import { mapGetters } from 'vuex';
import StarRatingComponent from '../StarRatingContainer/StarRating/StarRating.vue';
import ratingTexts from './RatingTexts';

export default {
  name: 'ModalFormRating',
  components: { StarRatingComponent },
  props: {
    existingRating: Object,
    categories: Array,
  },
  data() {
    return {
      isSubmitting: false,
      starsHovered: 0,
      tempHover: 0,
      ratingData: null,
      publicPath: process.env.BASE_URL,
      reviewPlaceholder:
        'Example 1: If you rated the criteria "Quality" only. Describe your experience on Performance precisely. \n\nExample 2: If you rated "Costs" and "Innovation". Describe your experience on those two criteria Costs and Innovation precisely.',
      ratingEmptyState: {
        purchasingOrderNumber: '',
        title: '',
        isConfidential: false,
        recommendation: null,
        description: '',
        ratingScores: [
          {
            category: 'Simplified',
            ratingOptions: [
              { name: 'Quality', value: 0, text: this.getInfoTextForScore('Quality') },
              { name: 'Costs', value: 0, text: this.getInfoTextForScore('Costs') },
              { name: 'Innovation', value: 0, text: this.getInfoTextForScore('Innovation') },
              { name: 'Relationship', value: 0, text: this.getInfoTextForScore('Relationship') },
            ],
          },
        ],
      },
    };
  },
  created() {
    const { purchasingOrderNumber } = this.$route.query;
    if (purchasingOrderNumber) {
      this.ratingEmptyState.purchasingOrderNumber = purchasingOrderNumber;
    }

    this.ratingData = this.existingRating ? { ...this.existingRating } : this.ratingEmptyState;

    if (this.existingRating) {
      this.ratingData.ratingScores = this.categories.map((category) => {
        const scoresFromCategory = this.existingRating.ratingScores.filter((score) => score.category === category);
        const ratingOptions = scoresFromCategory.map((score) => ({
          name: score.name,
          value: score.value,
          text: this.getInfoTextForScore(score.name) || '',
          id: score.id,
        }));

        return {
          category,
          ratingOptions,
        };
      });
    }
  },
  methods: {
    getInfoTextForScore(key) {
      return ratingTexts[key];
    },
    saveRating() {
      this.isSubmitting = true;
      const emitAction = this.existingRating ? 'edit' : 'create';
      this.$emit(emitAction, { ...this.ratingData });
    },
    cancel() {
      this.$emit('close');
    },
    onChange(property, value) {
      this.ratingData[property] = value;
    },
    markStar(index) {
      // eslint-disable-next-line
      this.starsHovered === index + 1 ? (this.starsHovered = 0) : (this.starsHovered = index + 1);

      this.ratingData.ratingScores.filter((r) => r.category === 'Recommendation')[0].ratingOptions[0].value = this.starsHovered;
    },
    hoverStar(index) {
      this.tempHover = index + 1;
    },
    leaveStar() {
      this.tempHover = this.starsHovered;
    },
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),
    isFormValid() {
      return this.isOneRatingSelected;
    },
    isNew() {
      return this.existingRating === undefined;
    },
    isOneRatingSelected() {
      return this.ratingData.ratingScores.some((ratingScore) => ratingScore.ratingOptions.some((ratingOption) => ratingOption.value > 0));
    },
  },
};
