import httpService from './httpService';

const getBySupplierId = async supplierId => {
  const { data } = await httpService.get(`/measures/supplier/${supplierId}`);
  return data;
};

const updateMeasure = async payload => {
  const { data } = await httpService.put('/measures', payload);
  return data;
};

const createMeasure = async (supplierId, payload) => {
  const { data } = await httpService.post(`/measures/supplier/${supplierId}`, payload);
  return data;
};

const deleteMeasure = async measureId => {
  const { data } = await httpService.delete(`/measures/${measureId}`);
  return data;
};

export default {
  getBySupplierId,
  updateMeasure,
  createMeasure,
  deleteMeasure
};
