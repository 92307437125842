/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable no-return-assign */
/* eslint-disable function-paren-newline */
import Datepicker from 'vuejs-datepicker';
import { mapGetters } from 'vuex';
import ContractService from '../../../../services/ContractService';

export default {
  name: 'Contracts',
  components: {
    Datepicker
  },
  data() {
    return {
      search: '',
      contractLink: 'http://portal.corproot.net/irj/portal/kontrakt_anz',
      ASC: true,
      result: '',
      deletePopUp: {
        show: false,
        id: undefined
      },
      show: true,
      showForm: false,
      close: false,
      form: {
        showFormular: false,
        name: '',
        type: '',
        link: '',
        number: '',
        targetValue: 0,
        description: '',
        origin: 'PLATFORM',
        customType: ''
      },
      contractTypes: [],
      contracts: []
    };
  },
  async created() {
    if (this.getClickedSupplier && !this.getClickedSupplier.contracts) {
      this.getClickedSupplier.contracts = await ContractService.getSupplierContracts(this.getClickedSupplier.id);
    }
    this.contractTypes = await ContractService.getContractTypes();
    this.contracts = this.getClickedSupplier.contracts;
  },
  methods: {
    cancelForm() {
      this.showForm = false;
    },
    sortStringArray(toSort, asc, paramName) {
      if (asc === true) {
        const result = toSort.sort((a, b) => (a[paramName] > b[paramName] ? 1 : -1));
        return result;
        // eslint-disable-next-line no-else-return
      } else {
        const result = toSort.sort((a, b) => (b[paramName] > a[paramName] ? 1 : -1));
        return result;
      }
    },
    sortDateArray(toSort, asc, paramName) {
      if (asc === true) {
        const result = toSort.sort((a, b) => new Date(b[paramName]) - new Date(a[paramName]));
        return result;
        // eslint-disable-next-line no-else-return
      } else {
        const result = toSort.sort((a, b) => new Date(a[paramName]) - new Date(b[paramName]));
        return result;
      }
    },
    async saveContract() {
      const contract = {
        ...this.form,
        type: this.form.type.toLowerCase() === 'other' ? this.form.customType : this.form.type,
        origin: 'PLATFORM',
        showForm: undefined,
        customType: undefined
      };

      await ContractService.createContract(contract, this.getClickedSupplier.id).then(data => {
        this.contracts.push(data);
        this.cancelForm();
      });
    },
    openDeletePopUp(contract) {
      this.deletePopUp.show = true;
      this.deletePopUp.id = contract.id;
    },
    async deleteContract(contractId) {
      await ContractService.deleteContract(contractId).then(() => {
        this.getClickedSupplier.contracts = this.getClickedSupplier.contracts.filter(el => el.id !== contractId);
        this.contracts = this.getClickedSupplier.contracts;
        this.deletePopUp.show = false;
      });
    }
  },
  computed: {
    ...mapGetters(['getClickedSupplier']),
    checkContractsValidity() {
      return !!(this.form.type && this.form.expiration && this.form.start && this.form.description && this.form.name && this.form.number);
    },
    canSave() {
      return (
        this.form &&
        this.form.type &&
        this.form.type.trim().length > 0 &&
        this.form.start &&
        this.form.expiration &&
        new Date(this.form.expiration) >= new Date(this.form.start) &&
        this.form.description &&
        this.form.description.trim().length > 0 &&
        this.form.number &&
        this.form.number !== '' &&
        this.form.name &&
        this.form.name.trim().length > 0 &&
        (this.form.type.toLowerCase() === 'other' ? this.form.customType && this.form.customType.trim().length > 0 : true) &&
        (typeof this.form.number === 'string' || typeof this.form.number === 'number')
      );
    },
    sapContracts() {
      return this.contracts === null
        ? null
        : this.contracts
        ? this.contracts.filter(
            el =>
              // eslint-disable-next-line implicit-arrow-linebreak
              el.origin === 'SAP' &&
              (this.search.trim().length === 0
                ? true
                : el.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                  `${el.number}`.indexOf(this.search) > -1 ||
                  el.purchaser.toLowerCase().indexOf(this.search.toLowerCase()) > -1 ||
                  el.type.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
          )
        : [];
    },
    customContracts() {
      return this.contracts === null
        ? null
        : this.contracts
        ? this.contracts.filter(
            el => el.origin === 'PLATFORM' && (this.search.trim().length === 0 ? true : el.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1 || `${el.number}`.indexOf(this.search) > -1)
          )
        : [];
    },
    isLoading() {
      let lodingState = '';
      if (this.sapContracts === null) {
        lodingState = 'isLoading';
      } else if (this.sapContracts.length > 0) {
        lodingState = 'isNotLoading';
      } else if (this.sapContracts.length === 0 && this.search === '') {
        lodingState = 'isEmpty';
      } else {
        lodingState = 'isNotLoading';
      }
      return lodingState;
    },
    customContractsIsLoading() {
      let lodingState = '';
      if (this.customContracts === null) {
        lodingState = 'isLoading';
      } else if (this.customContracts.length > 0) {
        lodingState = 'isNotLoading';
      } else if (this.customContracts.length === 0 && this.search === '') {
        lodingState = 'isEmpty';
      } else {
        lodingState = 'isNotLoading';
      }
      return lodingState;
    }
  }
};
