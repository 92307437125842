import Vue from 'vue';
import infiniteScroll from 'vue-infinite-scroll';
import VueContentPlaceholders from 'vue-content-placeholders';
import format from 'date-fns/format';
import VTooltip from 'v-tooltip';
import { defineCustomElements } from '@swisscom/sdx/dist/js/webcomponents/loader';
import '@swisscom/sdx/dist/css/sdx.min.css';

import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';

Vue.use(infiniteScroll);
Vue.use(VueContentPlaceholders);
Vue.use(VTooltip);

Vue.config.ignoredElements = [/sdx-\w*/];

defineCustomElements(window);

Vue.config.productionTip = false;

Vue.filter('formatDate', (val, f = 'dd.MM.yyyy') => {
  if (val) {
    return format(new Date(val), f);
  }
  return undefined;
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
