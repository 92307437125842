/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import { vuexOidcCreateStoreModule } from 'vuex-oidc';
// eslint-disable-next-line import/no-cycle
import Supplier from './supplier/Supplier';
import Rating from './supplier/Rating';
import User from './user/User';
import oidcSettings from '../config/oidc';
import Filter from './supplier/Filter';
// eslint-disable-next-line import/no-cycle
import Detail from './supplier/Detail';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    oidcStore: vuexOidcCreateStoreModule(oidcSettings, { namespaced: true, publicRoutePaths: ['/oidc/callback'] }),
    Supplier,
    Filter,
    Detail,
    Rating,
    User
  }
});
